/* global Common,CommonApi,$,Const,Env */
/**
 * @file ヘッダー
 * @author Ozaki Takao
 */
(function () {
  'use select';
  if (typeof window.Header === 'undefined') {
    window.Header = {};
  }

  /**
   * コンストラクタ
   *
   * @constructor
   * @param {string} ヘッダー挿入箇所のid
   */
  window.Header = function (id) {
    /**
     * ヘッダー挿入箇所のid
     * @type {string}
     */
    this.id = id;
    window.headerIns = this;
  };

  window.Header.prototype = {
    HTML_HEADER_IN: `
      <div class="header-in">
      </div><!-- /header-in -->
    `,
    HTML_HEADER_IN_OPERATION: `
      <div class="header-in-operation">
      </div><!-- /header-in-operation -->
    `,
    HTML_CONTENT: `
      <h1 class="header-logo">
        <a name="header-logo" class="is-opacity" href="/"><img src="/common/img/logo.png" alt="TOA"></a>
        <span class="header-version" name="label-version"></span>
      </h1>
      <div class="header-utility">
        <div class="page">${Env.SYSTEM_NAME}
        </div>
        <div class="alert has-notification">
          <span id="header-notification-menu-target"><img src="/common/img/ico_alert_01.png" alt=""/></span>
        </div>
        <div class="user">
          <div class="user-in" id="header-user-menu-target">
            <span name="label-user-name"></span>
          </div>
        </div>
      </div><!-- /header-utility -->
    `,
    HTML_LOGOUT: `
      <div class="box-logout">
        <div class="box-in">
          <p class="text"><a href="#dummy" style="color:black;" name="btn-logout">ログアウト</a></p>
        </div>
      </div>
    `,
    /**
     * 初期処理
     * @access public
     * @param {object} オプション
     */
    init: function (option) {
      var that = this;
      that.option = option;
      if (that.option && that.option.designType === Const.DESIGN_TYPE.OPERATION) {
        // デザイン種別：端末操作の場合
        that.$HeaderInEle = $(that.HTML_HEADER_IN_OPERATION);
      } else {
        that.$HeaderInEle = $(that.HTML_HEADER_IN);
      }
      that.$HeaderInEle.eq(0).append(that.HTML_CONTENT);

      var $HeaderEle = $('#' + that.id).append(that.$HeaderInEle);
      var $LogoutEle = $(that.HTML_LOGOUT);
      that.$HeaderInEle.eq(0).after($LogoutEle);
      $(document).on('click', function (event) {
        // ユーザー領域外をクリックした場合
        if ($(event.target).closest($LogoutEle.add('#header-user-menu-target')).length === 0) {
          // ユーザー領域を閉じる
          window.pulldown.hideUserArea();
        }
      });

      // ロゴ押下
      $HeaderEle.find('[name="header-logo"]').on('click', function (event) {
        event.originalEvent.preventDefault();

        // 端末のユーザグループ情報をセッションから削除
        Common.removeSession(Const.SESSION_KEY.USER_GROUP_TERMINAL);
        // 個別端末画面の前画面情報をセッションから削除
        Common.removeSession(Const.SESSION_KEY.TERMINAL_DETAIL_PREV_PAGE);

        Common.pageTransitionByUserType();
      });

      // バージョン
      var version = Const.RELEASE_VERSION;
      $HeaderEle.find('[name="label-version"]').text(version);

      // ユーザー名称
      var userName = Common.getUserInfo(Const.SESSION_USER_INFO.USER_NAME);
      var dispUserName = userName ? userName + ' 様' : '';
      $HeaderEle.find('[name="label-user-name"]').text(dispUserName).attr('title', dispUserName);

      // ログアウト
      $HeaderEle.find('[name="btn-logout"]').on('click', function (event) {
        event.originalEvent.preventDefault();
        Common.logout();
      });

      // お知らせ（通知日時の新しい順から最大5件取得）
      var apiParam = {
        limit: 5,
        user_type: Common.getUserInfo(Const.SESSION_USER_INFO.USER_TYPE)
      };
      CommonApi.getInformation(apiParam).then(function (data) {
        if (!Array.isArray(data.information_list)) {
          return;
        }
        var $InformationContentEle = that.createInformationContent_(data.information_list);
        $('#' + that.id).append($InformationContentEle);
      });
    },
    /**
     * タイトル部（サブヘッダー部）をヘッダー領域に設定する
     * @access public
     * @param {object} $TitleELe タイトル部（サブヘッダー部）エレメント
     */
    setTitle: function ($TitleELe) {
      var that = this;
      that.$TitleELe && that.$TitleELe.remove();
      if ($TitleELe) {
        that.$HeaderInEle && that.$HeaderInEle.eq(0).after($TitleELe);
      }
      that.$TitleELe = $TitleELe || null;
    },
    /**
     * お知らせ（領域）の作成
     * @access private
     * @param {array} informationList お知らせ情報リスト
     * @returns {object} お知らせ（領域）のjQuery要素
     */
    createInformationContent_: function (informationList) {
      var that = this;
      var html = `
        <div class="box-notification">
          <div class="box-in">
            <ul class="list-notification-item" name="list-information">
            </ul>
          </div><!-- / .box-in -->
          <div class="s-ta-r s-mr-25">
            <a class="link-cmn-01" href="#dummy" name="btn-more">もっと見る</a>
          </div>
        </div><!-- / .box-notification -->
      `;
      var $InformationContent = $(html);
      // お知らせリスト
      var $InformationListEle = $InformationContent.find('[name="list-information"]');
      informationList.forEach(function (information) {
        $InformationListEle.append(that.createInformationItemEle_(information));
      });
      // もっと見る
      $InformationContent.find('[name="btn-more"]').on('click', function (event) {
        event.originalEvent.preventDefault();

        // 端末のユーザグループ情報をセッションから削除
        Common.removeSession(Const.SESSION_KEY.USER_GROUP_TERMINAL);
        // 個別端末画面の前画面情報をセッションから削除
        Common.removeSession(Const.SESSION_KEY.TERMINAL_DETAIL_PREV_PAGE);

        // お知らせ一覧画面に遷移する
        if (that.option && that.option.designType === Const.DESIGN_TYPE.OPERATION) {
          Common.pageTransition(Common.PAGE.INFORMATION_LIST);
        } else {
          window.location.href = '/home/information.html';
        }
        // お知らせ（領域）を閉じる
        window.pulldown.hideNotifyArea();
      });
      $(document).on('click', function (event) {
        // お知らせ領域外をクリックした場合
        if ($(event.target).closest($InformationContent.add('#header-notification-menu-target')).length === 0) {
          // お知らせ（領域）を閉じる
          window.pulldown.hideNotifyArea();
        }
      });
      return $InformationContent;
    },
    /**
     * お知らせ（アイテム）の作成
     * @access private
     * @param {object} information お知らせ情報
     * @returns {object} お知らせアイテムのjQuery要素
     */
    createInformationItemEle_: function (information) {
      var informedTime = information.informed_time;
      var escInformedTime = Common.escapeHtml(informedTime);
      var content = information.content;
      var escContent = Common.escapeHtml(content);
      var lfReplacedEscContent = (typeof escContent === 'string') ? escContent.replace(/\n/g, '<br>') : '';
      var html = `
        <li class="item">
          <p class="item-date">${escInformedTime}</p>
          <p class="item-title">お知らせ</p>
          <p class="item-description">${lfReplacedEscContent}</p>
        </li><!-- / .item -->
      `;
      return $(html);
    }
  };
}());
